import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Nav from './components/Nav';
import './styles/App.css';
import { useState, useEffect } from 'react';

function App() {
    return (
        <>
            <Header />
            <Main />
        </>
    );
}

function Main() {
    return (
        <div className='main-wrapper'>
            <aside className='left-side'>
                <Nav />
            </aside>
            <main>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Welcome />} />
                    </Routes>
                </BrowserRouter>
            </main>
            <aside className='right-side'>
                <ModuleProfile />
            </aside>
        </div>
    );
}

function Welcome() {
    return (
        <section className='content-wrapper'>
            <h1>환영합니다!</h1>
            <hr></hr>
            <p>
                카라쿤의 퍼즐 월드에 오신 것을 환영합니다!
            </p>
        </section>
    );
}

function ModuleProfile() {
    const [mode, setMode] = useState('');
    let content = null;

    useEffect(() => {
        fetch('https://karakoon.kr/user/authcheck')
        .then((res) => res.json())
        .then((json) => {
            if (json.isLogin === 'True') {
                setMode('PROFILE');
            } else {
                setMode('LOGIN')
            }
        })
    }, []);

    if (mode === 'LOGIN') {
        content = <Login setMode={setMode}></Login>
    } else if (mode === 'REGISTER') {
        content = <Register setMode={setMode}></Register>
    } else if (mode === 'PROFILE') {
        content = <Profile setMode={setMode}></Profile>
    }
    
    return (
        <>
            {content}
        </>
    );
}

function Login(props) {
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [notice, setNotice] = useState('');

    const login = (event) => {
        event.preventDefault();

        const userData = {
            userId: id,
            userPassword: password
        };

        fetch('https://karakoon.kr/user/login', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(userData)
        })
        .then((res) => res.json())
        .then((json) => {
            if (json.isLogin === 'True') {
                props.setMode('PROFILE');
            } else {
                setNotice(json.isLogin);
            }
        });
    }

    return (
        <div className='module' id='login'>
            <form>
                <div className='item'>
                    <label htmlFor='id'>아이디</label>
                    <input type='text' id='id' onChange={event => setId(event.target.value)}></input>
                </div>
                <div className='item'>
                    <label htmlFor='password'>비밀번호</label>
                    <input type='password' id='password' onChange={event => setPassword(event.target.value)}></input>
                </div>
                <span className='notice'> {notice} </span>
                <button type='submit' onClick={ login }>로그인</button>
            </form>
            <button type='button' onClick={() => { props.setMode('REGISTER'); }}>회원 가입하기</button>
        </div>
    );
}

function Register(props) {
    const [id, setId] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [nickname, setNickname] = useState('');

    const [idValid, setIdValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [passwordRepeatValid, setPasswordRepeatValid] = useState(false);
    const [nicknameValid, setNicknameValid] = useState(false);
    
    const [idNotice, setIdNotice] = useState('');
    const [passwordNotice, setPasswordNotice] = useState('');
    const [passwordRepeatNotice, setPasswordRepeatNotice] = useState('');
    const [nicknameNotice, setNicknameNotice] = useState('');
    const [generalNotice, setGeneralNotice] = useState('');

    const register = (event) => {
        event.preventDefault();

        const userData = {
            userId: id,
            userPassword: password,
            userPasswordRepeat: passwordRepeat,
            userNickname: nickname
        };

        if (idValid && passwordValid && passwordRepeatValid && nicknameValid) {
            fetch('https://karakoon.kr/user/register', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(userData)
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.isSuccess === 'True') {
                    setGeneralNotice('회원 가입이 완료되었습니다!');
                } else {
                    setGeneralNotice(json.isSuccess);
                }
            });
        } else {
            setGeneralNotice('올바르지 않은 입력입니다.');
        }
    }

    const idCheck = (target) => {
        const idRegExp = /^[0-9a-zA-Z]{6,16}$/;

        setId(target);
        setIdValid(idRegExp.test(target));
        
        if (idRegExp.test(target)) {
            setIdNotice('');
        } else {
            setIdNotice('아이디 형식을 확인해주세요');
        }
    }

    const passwordCheck = (target) => {
        const passwordRegExp = /^[0-9a-zA-Z$`~!@$!%*#^?&\[\]\{\}\(\)\<\>\-_=+]{8,20}$/;
        
        setPassword(target);
        setPasswordValid(passwordRegExp.test(target));
        
        if (passwordRegExp.test(target)) {
            setPasswordNotice('');
        } else {
            setPasswordNotice('비밀번호 형식을 확인해주세요.');
        }

        setPasswordRepeatValid(target == passwordRepeat);

        if (target == passwordRepeat) {
            setPasswordRepeatNotice('');
        } else {
            setPasswordRepeatNotice('비밀번호가 일치하지 않습니다.');
        }
    }

    const passwordRepeatCheck = (target) => {
        setPasswordRepeat(target);
        setPasswordRepeatValid(password == target);

        if (password == target) {
            setPasswordRepeatNotice('');
        } else {
            setPasswordRepeatNotice('비밀번호가 일치하지 않습니다.');
        }
    }

    const nicknameCheck = (target) => {
        const nicknameRegExp = /^[ㄱ-ㅎㅏ-ㅣ가-힣]{2,10}$/;

        setNickname(target);
        setNicknameValid(nicknameRegExp.test(target));

        if (nicknameRegExp.test(target)) {
            setNicknameNotice('');
        } else {
            setNicknameNotice('닉네임 형식을 확인해주세요.');
        }
    }

    return (
        <div className='module' id='register'>
            <form>
                <div className='item'>
                    <label htmlFor='id'>아이디</label>
                    <span className='inform'>6~16자</span>
                    <span className='inform'>영문, 숫자 사용 가능</span>
                    <input type='text' id='id' onChange={(event) => { idCheck(event.target.value) }}></input>
                    <span className='notice'> {idNotice} </span>
                </div>
                <div className='item'>
                    <label htmlFor='password'>비밀번호</label>
                    <span className='inform'>8~20자</span>
                    <span className='inform'>영문, 숫자, 기호 사용 가능</span>
                    <input type='password' id='password' onChange={(event) => { passwordCheck(event.target.value) }}></input>
                    <span className='notice'> {passwordNotice} </span>
                </div>
                <div className='item'>
                    <label htmlFor='passwordRepeat'>비밀번호 확인</label>
                    <input type='password' id='passwordRepeat' onChange={(event) => { passwordRepeatCheck(event.target.value) }}></input>
                    <span className='notice'> {passwordRepeatNotice} </span>
                </div>
                <div className='item'>
                    <label htmlFor='nickname'>닉네임</label>
                    <span className='inform'>2~8자</span>
                    <span className='inform'>한글 사용 가능</span>
                    <input type='text' id='nickname' onChange={(event) => { nicknameCheck(event.target.value) }}></input>
                    <span className='notice'> {nicknameNotice} </span>
                </div>
                <span className='notice'> {generalNotice} </span>
                <button type='submit' onClick={ register }>회원 가입</button>
            </form>
            <button type='button' onClick={() => { props.setMode('LOGIN'); }}>로그인 화면으로</button>
        </div>
    );
}

function Profile(props) {
    const [nickname, setNickname] = useState('');

    fetch('https://karakoon.kr/user/nickname', {
        'method': 'GET',
    })
    .then((res) => res.json())
    .then((json) => {
        setNickname(json.nickname);
    });

    const logout = () => {
        fetch('https://karakoon.kr/user/logout', {
            method: 'GET'
        })
        .then(() => {
            props.setMode('LOGIN');
        });
    }

    return (
        <div className='module' id='profile'>
            <span className='nickname'>{ nickname }</span>
            <button type='button' onClick={ logout }>로그아웃</button>
        </div>
    );
}

export default App;