import React from "react";
import { BrowserRouter, Link } from "react-router-dom";

function Nav() {
    return (
        <BrowserRouter>
            <nav>
                <ol>
                    <li className='section'><Link to='/puzzlehunt'><span className='section'>퍼즐 헌트</span></Link></li>
                    <li><Link to='/puzzlehunt/mini/'>미니 퍼즐 헌트</Link></li>
                    <li><Link to='/puzzlehunt/general/'>정규 퍼즐 헌트</Link></li>
                </ol>
            </nav>
        </BrowserRouter>
    );
}

export default Nav;