import React from "react";
import { BrowserRouter, Link } from "react-router-dom";

function Header() {
    return (
        <header>
            <div className='top-wrapper'>
                <span className='top-title'>
                    <BrowserRouter>
                        <Link to='/'>카라쿤의 퍼즐 월드</Link>
                    </BrowserRouter>
                </span>
            </div>
        </header>
    );
}

export default Header;